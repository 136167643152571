<template>
  <el-dialog
    title="选择提交人"
    :visible.sync="selectSubmitPersonVisible"
    width="30%"
    :before-close="handleClose">
    <div>
      <el-form :model="ruleForm"  :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="" prop="name">
          <el-select style="width: 80%" multiple clearable v-model="ruleForm.region" placeholder="请选择">
            <el-option v-for="(item, index) in configOption" :key="index" :label="item.label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
  export default {
    name: "selectSubmitPerson",
    props: {
      selectSubmitPersonVisible: {
        type: Boolean,
        default: false
      },
      submitPersonId: {
        type:String,
        default: ""
      }
    },
    data() {
     return {
       configOption: [
         {
           id: "1",
           label: '社区居委会'
         },
         {
           id: "2",
           label: '街道办事处'
         }
       ],
       ruleForm: {
         region: []
       },
       rules: {

       }
     }
    },
    mounted() {
      console.log(this.submitPersonId)
      if(!this.submitPersonId) return
      if(this.submitPersonId.indexOf(",") !== -1){
        this.ruleForm.region = this.submitPersonId.split(",")
      }else {
        this.ruleForm.region.push(this.submitPersonId)
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:selectSubmitPersonVisible', false)
      },
      submit() {
        let jsonArr = []
        this.configOption.forEach(item => {
          if(this.ruleForm.region.includes(item.id)) {
            jsonArr.push(item.label)
          }
        })
        this.$emit("postSelectData", { id:this.ruleForm.region.join(",") ,name: jsonArr.join(",")})
        this.$emit('update:selectSubmitPersonVisible', false)
      }
    }
  }
</script>

<style scoped>

</style>
