<template>
  <el-dialog
    title="选择审批人"
    :visible.sync="isAuditVisible"
    width="30%"
    :before-close="handleClose">
    <div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item label="是否需要社区居委会审核：" prop="name">
          <el-radio-group v-model="ruleForm.isMute">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
  export default {
    name: "isAudit",
    props: {
      isAuditVisible: {
        type: Boolean,
        default: false
      },
      isMute: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        ruleForm: {
          isMute: ""
        },
        rules: {

        }
      }
    },
    mounted() {
      this.ruleForm.isMute = this.isMute
    },
    methods: {
      handleClose() {
        this.$emit('update:isAuditVisible', false)
      },
      submit() {
        this.$emit('postAuditMute', this.ruleForm.isMute)
        this.$emit('update:isAuditVisible', false)
      }
    }
  }
</script>

<style scoped>

</style>
